import { Container, SimpleGrid, Box } from '@chakra-ui/react'
import { Card, CardProps } from 'common/components/molecules/Card'
import React from 'react'

export interface FlatcardsProps {
    cards: CardProps[]
    iconSize?: 'md' | 'lg'
}

export const Flatcards: React.FC<FlatcardsProps> = ({ cards, iconSize }) => {
    return (
        <Box data-testid='Flatcards' py={[16, 16, 20]}>
            <Container maxW='6xl'>
                <SimpleGrid columns={[1, 2]} spacing={8}>
                    {cards?.map(card => (
                        <Card
                            key={card.title}
                            {...card}
                            style={{ color: '#3A3160' }}
                            iconSize={iconSize}
                        />
                    ))}
                </SimpleGrid>
            </Container>
        </Box>
    )
}
