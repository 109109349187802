import React from 'react'
import { Stack, Container } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'

export interface FlatcardsHeadingProps {
    text: string
}

export const FlatcardsHeading: React.FC<FlatcardsHeadingProps> = ({ text }) => {
    return (
        <Container maxW="6xl">
            <Stack pb={10} justifyContent='center'>
                <Text textAlign='center' variant="title-lg" color="brand.900">{text}</Text>
            </Stack>
        </Container>
    )
}
