import { Box } from '@material-ui/core'
import { Video } from 'common/components/atoms/Video'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { AlertsProps } from 'common/components/organisms/Alerts'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import {
    CampaignJumbotron,
    CampaignJumbotronProps
} from 'common/components/organisms/CampaignJumbotron'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList/FeatureList'
import {
    Flatcards,
    FlatcardsProps
} from 'common/components/organisms/Flatcards'
import { FlatcardsHeading } from 'common/components/organisms/FlatcardsHeading'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ProductList,
    ProductListProps
} from 'common/components/organisms/ProductList'
import React from 'react'
import { CampaignSection } from './CampaignSection'

export interface CampaignLandingMediumProps {
    layout: LayoutProps
    jumbotron: CampaignJumbotronProps
    alerts: AlertsProps
    video: any
    campaignFeature: CampaignFeatureProps
    featureList: FeatureListProps
    flatCards: FlatcardsProps
    flatCardsHeading: string
    productList: ProductListProps
}

export const CampaignLandingMedium: React.FC<CampaignLandingMediumProps> = ({
    layout,
    jumbotron,
    flatCards,
    flatCardsHeading,
    alerts,
    video,
    campaignFeature,
    featureList,
    productList
}) => {
    return (
        <Layout {...layout}>
            <CampaignJumbotron {...jumbotron} />(
            {flatCards && (
                <>
                    <Box bgcolor='#fff' pt={9}>
                        <FlatcardsHeading text={flatCardsHeading} />
                        <Flatcards {...flatCards} iconSize='lg' />
                    </Box>
                </>
            )}
            {productList.title && <ProductList {...productList} />}
            {featureList.title && (
                <Wrapper>
                    <Spacer />
                    <FeatureList variant='top' {...featureList} />
                </Wrapper>
            )}
            {campaignFeature.title && (
                <Wrapper style={{ background: '#fff' }}>
                    <Video {...video} />
                    <CampaignSection
                        campaignFeature={campaignFeature}
                        alerts={alerts}
                    />
                </Wrapper>
            )}
        </Layout>
    )
}
