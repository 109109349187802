import {
    getJumbotronData,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { CampaignLandingMedium } from 'common/components/templates/CampaignLanding/CampaignLandingMedium'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface CampaignLandingMediumPageProps {
    pageContext: any
}

export const CampaignLandingMediumPage: React.FC<CampaignLandingMediumPageProps> = ({
    pageContext
}) => {
    return (
        <CampaignLandingMedium {...getCampaignLandingMediumData(pageContext)} />
    )
}

export default CampaignLandingMediumPage
export const getCampaignLandingMediumData = (data: any) => ({
    layout: {
        ...getLayoutData(data),
        jumbotronVariant: 'campaign' as JumbotronProps['variant']
    },
    jumbotron: {
        ...getJumbotronData(data),
        image: data.campaign_jumbotron_image,
        text: data.campaign_jumbotron_text,
        title: data.campaign_jumbotron_title,
        variant: 'campaign' as JumbotronProps['variant']
    },
    video: {
        height: data.video__height,
        width: data.video__width,
        url: data.video__url,
        source: data.video__video,
        title: data.video__title,
        text: data.video__text
    },
    productList: {
        heading: data.productlist_heading,
        text: data.productlist_text,
        title: data.productlist_title,

        products: (data.products || []).map((product: any) => ({
            icon: product.icon,
            title: product.title,
            text: product.text,
            subtitle: product.subtitle,
            callToAction: {
                label: product.card_cta_0396ba7__cta_label,
                variant: product.card_cta_0396ba7__cta_variant,
                url: product.card_cta_0396ba7__cta_url
            }
        }))
    },
    featureList: {
        text: data.featurelist_text,
        callToAction: {
            analyticsId: data.featurelist__cta_analyticsid,
            label: data.featurelist__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist__cta_icon,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant,
            noSpacer: true
        },
        image: data.featurelist_image,
        title: data.featurelist_title,
        subtitle: data.featurelist_subtitle,
        features: data.featurelist_features
    },
    flatCards: {
        cards: data.flatcards__cards.map((card: any) => ({
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            },
            icon: card.icon,
            text: card.text,
            title: card.title
        }))
    },
    flatCardsHeading: data.flatcards_heading,
    campaignFeature: {
        title: data.campaign_feature_title,
        callToAction: {
            [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
            url: data.call_to_action__url,
            text: data.call_to_action__text,
            title: data.call_to_action__title,
            variant: data.call_to_action__variant,
            analyticsId: data.call_to_action__analytics_id,
            label: data.call_to_action__label
        },
        text: data.campaign_feature_text,
        image: data.campaign_feature_image
    },
    alerts: {
        alerts: data.alerts__alerts?.map((alert: any) => alert.text)
    }
})
